
const OurPeople = (props) => {
  return (
    <div>
      {/* -------------------- Block 1 -------------------- */}
      <div className="bg-dark-green px-10 mobile:px-20 md:px-40 py-20 text-left">
        <h1 className="text-gold text-4xl monomaniac-one mb-5">Our People</h1>
        <div>
          <ul className="marker:text-gold list-disc border border-white border-l border-r-0 border-y-0 pl-[10.5px]">
            <li className="pl-4">
              <div className="inline-flex flex-col w-full">
                <div className="text-gold text-xl monomaniac-one mb-1">Our Staff</div>
                <div className="text-white text-sm mb-5 jet-brains-mono">
                  To contact our staff, please email <a href="mailto:admin@alhaqqfoundation.ca" className="text-gold underline underline-offset-4 decoration-1">
                    admin@alhaqqfoundation.ca
                  </a>
                </div>
                <div className="jet-brains-mono mb-5">
                  <p className="text-white text-sm mb-5">We currently have <span className="text-gold">10+ volunteers</span> working with us!</p>
                  <p className="text-white text-sm mb-5">Their investment of time and energy allows us to achieve and maintain our vision. All of their support is vital to our success!</p>
                  <p className="text-white text-sm mb-5">If you are interested in being a part of our mission, please refer to the areas of interest for where you can get involved! </p>
                </div>
              </div>
            </li>
            <li className="pl-4">
              <div className="inline-flex flex-col w-full">
                <div className="text-gold text-xl monomaniac-one mb-1">Our Board of Directors</div>
                <div className="text-white text-sm mb-10 jet-brains-mono">
                  To contact the board, please email <a href="mailto:board@alhaqqfoundation.ca" className="text-gold underline underline-offset-4 decoration-1">
                    board@alhaqqfoundation.ca
                  </a>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-3">
                  <div className="monomaniac-one mb-10">
                    <h1 className="text-gold text-xl">Ariyan Haq</h1>
                    <p className="text-white text-sm">Board Chair</p>
                  </div>
                  <div className="monomaniac-one mb-10">
                    <h1 className="text-gold text-xl">Mohammed Garrah</h1>
                    <p className="text-white text-sm">Board Treasurer</p>
                  </div>
                  <div className="monomaniac-one mb-10">
                    <h1 className="text-gold text-xl">Mahmoud Abdul-Aziz</h1>
                    <p className="text-white text-sm">Board Secretary</p>
                  </div>
                </div>
              </div>
            </li>
            <li className="pl-4">
              <div className="inline-flex flex-col">
                <div className="text-gold text-xl monomaniac-one mb-1">About Our Board</div>
                <div className="text-white text-sm mb-5 jet-brains-mono">
                  Our Board of Directors is dedicated to upholding our values of transparency,
                  innovation, balance, and a holistic methodology. We bring a diverse range of
                  experience and experience while being unified by a shared vision of fostering
                  positive change and promoting social justice.
                </div>
                <div className="text-white text-sm mb-10 jet-brains-mono">
                  We aim to make ourselves readily available to our community and be actively involved in the development of this organization!
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      {/* -------------------- Block 2 -------------------- */}
      <div className="flex flex-col items-center bg-gold px-10 mobile:px-20 md:px-40 py-4">
        <h1 className="text-black text-4xl monomaniac-one mb-5">
          Get Involved With Us!
        </h1>
        <div className="text-black flex w-full justify-evenly text-sm jet-brains-mono font-medium  mb-5">
          Our organization only runs on volunteers meaning it is very important for us to find people to help.
          If what we do speaks to you, submit an application and we will do our best to get you involved! Thank you.
        </div>
        <button
          type="button"
          className="bg-dark-green hover:bg-green text-2xl rounded-md px-10 pt-1 pb-2 text-gold shadow-md shadow-black/100 hover:shadow-sm hover:shadow-black/100 active:shadow active:shadow-transparent"
          style={{
            fontFamily: 'Monomaniac One'
          }}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export default OurPeople