
const Volunteering = (props) => {
  return (
    <div>
      {/* -------------------- Block 1 -------------------- */}
      <div className="bg-dark-green px-10 mobile:px-20 md:px-40 py-20 text-left">
        <h1 className="text-gold text-4xl monomaniac-one mb-5">Volunteering</h1>
        <div>
          <ul className="marker:text-gold list-disc border border-white border-l border-r-0 border-y-0 pl-[10.5px]">
            <li className="pl-4 mb-10">
              <div className="inline-flex flex-col w-full align-middle">
                <div className="text-white text-sm jet-brains-mono">
                  Volunteers will always be our most valuable asset. The time and energy you commit is the main reason that we can make a difference.
                  <span className="text-gold"> Help us help others.</span>
                </div>
              </div>
            </li>

            <li className="pl-4 mb-10">
              <div className="inline-flex flex-col w-full align-middle">
                <div className="text-white text-sm jet-brains-mono">
                  We will be posting specific opportunities on the volunteer board of the Pillar Nonprofit Network.
                  This can be accessed at <a className="text-gold hover:underline" href="pillarnonprofit.ca/volunteer">pillarnonprofit.ca/volunteer.</a> However,
                  feel free to use the general application form below and we will review applicants when new opportunities arise.
                </div>
              </div>
            </li>
            <li className="pl-4 mb-10">
              <div
                className="inline-flex flex-col w-full rounded-sm border border-gold p-2 align-middle"
                style={{
                  backgroundColor: 'rgba(217, 217, 217, 0.03)',
                }}
              >
                <div className="text-white text-sm mb-5 jet-brains-mono">
                  “Indeed, Allah does not do injustice, [even] as much as an atom’s weight; while if there is a good deed, He multiplies it and gives from Himself a great reward.”
                </div>
                <div className="text-white text-md mb-5 ml-5 jet-brains-mono">
                  اِنَّ اللّٰهَ لَا يَظۡلِمُ مِثۡقَالَ ذَرَّةٍ​ ۚ وَاِنۡ تَكُ حَسَنَةً يُّضٰعِفۡهَا وَيُؤۡتِ مِنۡ لَّدُنۡهُ اَجۡرًا عَظِيۡمًا‏
                </div>
                <div className="text-white text-sm jet-brains-mono">
                  Quran [4:40]
                </div>
              </div>
            </li>
            <li className="pl-4">
              <button
                type="button"
                className="bg-gold hover:bg-dark-gold text-2xl rounded-md px-10 pt-1 pb-2 text-black shadow-md shadow-black/100
                hover:shadow-sm hover:shadow-black/100 active:shadow active:shadow-transparent align-middle"
                style={{
                  fontFamily: 'Monomaniac One'
                }}
              >
                Apply
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Volunteering